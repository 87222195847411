import React from 'react';
import {Breadcrumb, ConfigProvider, Layout, theme, Typography} from "antd";
import MainHeader from "../Header/Header";
import {Content} from "antd/es/layout/layout";
import MainFooter from "../Footer/Footer";
const { Title,Paragraph } = Typography;

function MainAbout() {

    return (
        <div>
            <Layout>
                <ConfigProvider   theme={{
                    algorithm: theme.darkAlgorithm,
                    components: {
                        Layout: {
                        },
                    },
                }}>
                <MainHeader />
                    <Layout style={{flex: 1, minHeight: "90vh"}}>
                        <Content style={{ padding: '0 48px', flexGrow: 1 }}>
                            <Breadcrumb style={{margin: '16px 0'}}>
                                <Breadcrumb.Item>About</Breadcrumb.Item>
                            </Breadcrumb>
                            <Typography>
                            <Title>About</Title>
                            <Paragraph>
                                我是KaliJerry，PBlaze Tech 的站长。我热爱编程，并相信科技可以改变世界。
                            </Paragraph>
                            <Paragraph>
                                创建 PBlaze Tech 的初衷是分享我开发的实用小工具，并与其他开发者交流学习。
                            </Paragraph>
                            <Paragraph>
                                目前，我正在专注于 React 前端开发，并计划开发一款更注重用户隐私的加密聊天软件。
                            </Paragraph>
                            <Paragraph>
                                欢迎访问我的 <a href='https://github.com/zdwtest'>Github</a>，查看我的项目，并提出宝贵的建议！
                            </Paragraph>
                            </Typography>
                        </Content>
                    </Layout>
                    <MainFooter />
                </ConfigProvider>
            </Layout>
        </div>
    )
}
export default MainAbout;