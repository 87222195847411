import React from 'react';
import {Breadcrumb, ConfigProvider, Layout, theme, Typography} from "antd";
import MainHeader from "../Header/Header";
import {Content} from "antd/es/layout/layout";
import MainFooter from "../Footer/Footer";
const { Title } = Typography;


function MainAI() {

    return (
        <Layout>
            <ConfigProvider   theme={{
                algorithm: theme.darkAlgorithm,
                components: {
                    Layout: {
                    },
                },
            }}>
                <MainHeader />
                <Layout style={{flex: 1, minHeight: "90vh"}}>
                    <Content style={{padding: '0 48px', flexGrow: 1}}>
                        <Breadcrumb style={{margin: '16px 0'}}>
                            <Breadcrumb.Item>AI</Breadcrumb.Item>
                        </Breadcrumb>
                    <Title style={{margin: '16px 0'}}>功能尚在开发，各位敬请等待</Title>
                    </Content>
                </Layout>
                <MainFooter/>
            </ConfigProvider>
        </Layout>
    )
}
export default MainAI;