import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {Layout, Breadcrumb, theme, ConfigProvider} from 'antd';
import MainHeader from "./tsx/Main/Header/Header";
import MainFooter from "./tsx/Main/Footer/Footer";
import MainAbout from "./tsx/Main/About/About";
import MainCopyright from "./tsx/Main/Copyright/About";
import MainAI from "./tsx/Main/AI/MainAI";
const { Content} = Layout;





const Main: React.FC = () => {
  theme.useToken();
    return (
        <div className="App">
            <Layout>
                <ConfigProvider   theme={{
                    algorithm: theme.darkAlgorithm,
                    components: {
                        Layout: {
                        },
                    },
                }}>
            <Layout style={{ display: 'flex',minHeight: '95vh' }}>
                <MainHeader/>
                <Content style={{ padding: '0 48px', flexGrow: 1 }}> {/* flexGrow: 1 使 Content 占据剩余空间 */}
                    <Breadcrumb style={{margin: '16px 0'}}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                    </Breadcrumb>
                        <h1>欢迎来到PBlaze</h1>
                        <p>这是一个基于React的前端项目，组件库来自<a href={'https://ant-design.antgroup.com/index-cn'}>Ant Design</a></p>
                        <p>项目地址：<a href='https://github.com/zdwtest/pblaze_website'>Github.com</a>
                        </p>
                </Content>
                </Layout>
                    <MainFooter/>
                </ConfigProvider>
            </Layout>
        </div>
    );
};

function App() {
    return (
        <BrowserRouter>
            <div className="container">
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/ai" element={<MainAI />} />
                    <Route path="/about" element={<MainAbout />} />
                    <Route path="/copyright" element={<MainCopyright />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;