import React from 'react';
import {Breadcrumb, ConfigProvider, Layout, theme, Typography} from "antd";
import MainHeader from "../Header/Header";
import {Content} from "antd/es/layout/layout";
import MainFooter from "../Footer/Footer";
const { Title,Paragraph } = Typography;

function MainCopyright() {

    return (
            <div>
                <Layout>
                    <ConfigProvider theme={{
                        algorithm: theme.darkAlgorithm,
                        components: {
                            Layout: {},
                        },
                    }}>
                        <MainHeader/>
                        <Layout style={{flex: 1, minHeight: "90vh"}}>
                            <Content style={{padding: '0 48px', flexGrow: 1}}>
                                <Breadcrumb style={{margin: '16px 0'}}>
                                    <Breadcrumb.Item>Copyright</Breadcrumb.Item>
                                </Breadcrumb>
                                <Typography>
                                    <Title>Copyright</Title>
                                    <Paragraph>
                                        本网站内容受<a href="https://www.apache.org/licenses/LICENSE-2.0">Apache License 2.0 许可证</a>的约束。
                                    </Paragraph>
                                    <Paragraph>
                                        然而，本网站的原创内容（包括但不限于文本、图像、视频等）不得在中国大陆地区以任何形式复制、分发、传播或利用，除非获得 PBlaze tech 的书面许可。
                                    </Paragraph>
                                    <Paragraph>
                                        请联系 admin@pblaze.com 了解授权事宜。
                                    </Paragraph>
                                </Typography>
                            </Content>
                        </Layout>
                        <MainFooter/>
                    </ConfigProvider>
                </Layout>
            </div>
            )
            }
            export default MainCopyright;