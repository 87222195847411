import React from 'react';
import {Layout} from "antd";
const { Footer } = Layout;
function MainFooter() {
    const currentYear = new Date().getFullYear(); //版权年份
    return (
        <div>
            <Footer style={{ textAlign: 'center' }}>
                <p>Copyright © 2024-{currentYear} KaliJerry. Licensed under the <a
                    href="https://github.com/zdwtest/pblaze_website/blob/react/LICENSE" target="_blank"
                    rel="noopener noreferrer">Apache License 2.0</a> <a href="https://www.pblaze.com" target="_blank"
                                                                        rel="noopener noreferrer">PBlaze.com</a>.</p>
            </Footer>
        </div>
    )
}
export default MainFooter;